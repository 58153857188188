<div class="container border border-5 rounded border-primary">
  <div class="row">
    <div class="col-12">
      <p class="fs-3">{{ "cart.order.success.vps.title" | translate }}</p>
      <p class="fs-4">
        {{ "cart.order.success.vps.message1" | translate }}
      </p>
      <p class="fs-4">
        {{ "cart.order.success.vps.message2" | translate }}
      </p>
      <p class="fs-4">
        {{ "cart.order.success.vps.message3" | translate }}
      </p>
      <p class="fs-4">
        {{ "cart.order.success.vps.footer1" | translate }}
        <br />{{ "cart.order.success.vps.footer2" | translate }}
      </p>
    </div>
  </div>
</div>
