import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ConfirmDialogService } from "../../../../Core/Services/ConfirmDialog/confirm-dialog.service";
import { CurrencyService } from "../../../../Core/Services/Currency/currency.service";
import { SubscriptionCancelComponent } from "../subscription-cancel/subscription-cancel.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-subscription",
  standalone: true,
  imports: [CommonModule, TranslateModule, SubscriptionCancelComponent],
  templateUrl: "./subscription.component.html",
  styleUrl: "./subscription.component.scss",
})
export class SubscriptionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() subscription: any;
  orderDetails: any[];
  order: any;
  full_price: number = 0;

  price_types: any[] = [];

  findPriceType(id: number) {
    return this.price_types.find((price_type) => {
      return price_type.id == id;
    });
  }

  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private modalService: NgbModal,
    public currencyService: CurrencyService
  ) {}
  onLangChange?: Subscription;

  ngOnInit(): void {
    this.onLangChange = this.translate.onLangChange.subscribe((val) => {
      this.apiCall();
    });
  }
  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.apiCall();
  }

  apiCall() {
    console.log(this.subscription);
    this.apiService.getPriceTypes().subscribe((res) => {
      this.price_types = res.data;
    });
    this.apiService.getOrder(this.subscription.orderid).subscribe((res) => {
      this.order = res.data[0];
      console.log(this.order);
    });
    this.apiService
      .getOrderDetail(this.subscription.orderid)
      .subscribe((res) => {
        this.orderDetails = res.data;
        this.full_price = 0;
        let orders: any[] = [];
        this.orderDetails.forEach((order) => {
          if (order.productid != 105) {
            this.full_price += +order.sumbrutto;
            orders.push(order);
          }
        });
        this.orderDetails = orders;
      });
  }

  cancel() {
    let modalref = this.modalService.open(SubscriptionCancelComponent, {
      centered: true,
    });
    modalref.result.then(
      (result) => {
        if (result) {
          let subject =
            this.translate.instant("subscription-page.message.subject") +
            " " +
            this.order.docnumber;
          let message = this.translate.instant(
            "sub_cancel.reasons." + result.reason
          );
          if (result.reason == "other") {
            message += ": " + result.message;
          }
          this.apiService.unsubService(this.subscription.id).subscribe(() => {
            this.subscription.sts = "U";
            this.apiService.sendMessage("", "", subject, message).subscribe({});
          });
        }
      },
      (reason) => {}
    );
  }
  uncancel() {
    this.apiService.subService(this.subscription.id).subscribe(() => {
      this.subscription.sts = "C";
    });
  }
}
