import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { Subscription } from "rxjs";
import { Invoice } from "../../../Core/models/Invoice";
import { CommonModule } from "@angular/common";
import { UserService } from "../../../Core/Services/User/user.service";
import { CurrencyService } from "../../../Core/Services/Currency/currency.service";

@Component({
  selector: "app-invoices",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./invoices.component.html",
  styleUrl: "./invoices.component.scss",
})
export class InvoicesComponent implements OnInit, OnDestroy {
  constructor(
    private translateService: TranslateService,
    private apiService: ApiService,
    private userService: UserService,
    public currencyService: CurrencyService
  ) {}

  invoices: Invoice[] = [];

  onLangChange?: Subscription;
  ngOnInit(): void {
    this.onLangChange = this.translateService.onLangChange.subscribe((val) => {
      this.apiCalls();
    });

    this.apiCalls();
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
  }

  apiCalls() {
    this.apiService.getInvoices().subscribe((response) => {
      if (response.error) {
        this.userService.logout();
      }
      if (response.result) {
        console.log(response.data);
        this.invoices = response.data;
      }
    });
  }
}
