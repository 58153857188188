import { Component, OnInit } from '@angular/core';
import { FaqService, IFAQ } from '../../Core/Services/FAQ/faq.service';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [CommonModule, NgbCollapseModule, TranslateModule],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
})
export class FAQComponent implements OnInit {
  faqs: IFAQ[] = [];
  constructor(
    private faqService: FaqService,
    public translate: TranslateService,
    public serializer: DomSanitizer
  ) {}
  ngOnInit() {
    this.faqService.getFAQ().subscribe((data) => {
      this.faqs = data as IFAQ[];
      this.faqs.forEach((faq) => {
        faq.collapsed = true;
      });
    });
  }
}
