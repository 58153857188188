import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserService } from '../Services/User/user.service';
import { map } from 'rxjs';

export const LoggedIn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router: Router = inject(Router);
  return inject(UserService)
    .isUserLoggedIn()
    .pipe(
      map((LoggedIn) => {
        if (LoggedIn) return true;

        return router.createUrlTree(['/']);
      })
    );
};

export const NotLoggedIn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router: Router = inject(Router);
  return inject(UserService)
    .isUserLoggedIn()
    .pipe(
      map((LoggedIn) => {
        if (LoggedIn) return router.createUrlTree(['/']);
        return true;
      })
    );
};
