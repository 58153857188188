<div class="container">
  <div class="row" *ngFor="let card of cards">
    <div class="col-auto">
      <img
        class="card_icon"
        src="/assets/images/card_types/visa.svg"
        alt=""
        srcset=""
        *ngIf="card.card_brand == 'visa'"
      />
      <img
        class="card_icon"
        src="/assets/images/card_types/mastercard.svg"
        alt=""
        srcset=""
        *ngIf="card.card_brand == 'mastercard'"
      />
    </div>
    <div class="col-auto mt-4">
      <span class="fs-3">xxxx-xxxx-xxxx-{{ card.card_last_four }}</span>
    </div>
    <div class="col-auto mt-4">
      <span class="fs-3">{{ card.cardholder_name }}</span>
    </div>
    <div class="col-auto mt-4">
      <span class="fs-3">{{ card.card_expiry }}</span>
    </div>
    <div class="col mt-3">
      <button class="btn btn-outline-danger" (click)="deleteCard(card)">
        {{ "card.delete" | translate }}
      </button>
    </div>
  </div>
</div>
<button class="btn btn-secondary mt-5" (click)="testAddCard()">
  {{ "card.add" | translate }}
</button>
