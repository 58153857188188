import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const slideInAnimation = trigger("routeAnimations", [
  transition(
    "Home => VpsCategory, Home => Vps,Home => DedicatedCategory, Home => Dedicated , Home => ServerColotation, Home => EduService, Home => Contact, Home => Faq, Home => Cart," +
      "VpsCategory => Vps, VpsCategory => DedicatedCategory , VpsCategory => ServerColotation, VpsCategory => EduService, VpsCategory => Contact, VpsCategory => Faq, VpsCategory => Cart," +
      "DedicatedCategory => Dedicated, DedicatedCategory => ServerColotation, DedicatedCategory => EduService, DedicatedCategory => Contact, DedicatedCategory => Faq, DedicatedCategory => Cart," +
      "Vps => Contact, Vps => DedicatedCategory, Vps => ServerColotation, Vps => EduService, Vps => Cart, Vps => Faq," +
      "Dedicated => ServerColotation, Dedicated => EduService, Dedicated => Contact, Dedicated => Cart, Dedicated => Faq," +
      "ServerColocation => DedicatedCategory, ServerColocation => EduService, ServerColocation => VpsCategory, ServerColocation => Cart, ServerColocation => Faq, ServerColocation => Contact," +
      "EduService => Faq, EduService => Cart, EduService => Contact," +
      "Faq => Contact, Faq => Cart," +
      "Contact => Cart",

    [
      query(":enter, :leave", style({ position: "fixed", width: "100%" }), {
        optional: true,
      }),
      group([
        query(
          ":enter",
          [
            style({ transform: "translateX(100%)" }),
            animate("0.5s ease-in-out", style({ transform: "translateX(0%)" })),
          ],
          { optional: true }
        ),
        query(
          ":leave",
          [
            style({ transform: "translateX(0%)" }),
            animate(
              "0.5s ease-in-out",
              style({ transform: "translateX(-100%)" })
            ),
          ],
          { optional: true }
        ),
      ]),
    ]
  ),
  transition(
    "VpsCategory => Home," +
      "DedicatedCategory => Home, DedicatedCategory => VpsCategory," +
      "Vps => Home, Vps => VpsCategory," +
      "Dedicated => Home, Dedicated => DedicatedCategory, Dedicated => VpsCategory," +
      "Faq => Home, Faq => VpsCategory, Faq => DedicatedCategory, Faq => Vps, Faq => EduService, Faq => ServerColocation," +
      "EduService => ServerColotation, EduService => Home, EduService => VpsCategory, EduService => DedicatedCategory, " +
      "ServerColocation => Home, ServerColocation => VpsCategory, ServerColocation => DedicatedCategory," +
      "Contact => Home, Contact => VpsCategory, Contact => Vps, Contact => DedicatedCategory, Contact => Faq, Contact => EduService, Contact => ServerColocation," +
      "Cart => Home, Cart => VpsCategory, Cart => DedicatedCategory, Cart => Vps, Cart => Faq, Cart => Contact, Cart => EduService, Cart => ServerColocation",
    [
      query(":enter, :leave", style({ position: "fixed", width: "100%" }), {
        optional: true,
      }),
      group([
        query(
          ":enter",
          [
            style({ transform: "translateX(-100%)" }),
            animate("0.5s ease-in-out", style({ transform: "translateX(0%)" })),
          ],
          { optional: true }
        ),
        query(
          ":leave",
          [
            style({ transform: "translateX(0%)" }),
            animate(
              "0.5s ease-in-out",
              style({ transform: "translateX(100%)" })
            ),
          ],
          { optional: true }
        ),
      ]),
    ]
  ),
]);
