import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription, firstValueFrom } from "rxjs";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { ProductCategory } from "../../../Core/models/Product";
import { ScrollService } from "../../../Core/Services/Scroll/scroll.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CurrencyService } from "../../../Core/Services/Currency/currency.service";
import { DedicatedSubcategoriesPageComponent } from "../dedicated-subcategories-page/dedicated-subcategories-page.component";
import { DedicatedCardComponent } from "../dedicated-subcategories-page/dedicated-card/dedicated-card.component";

@Component({
  selector: "app-dedicated-categories-page",
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    TranslateModule,
    DedicatedSubcategoriesPageComponent,
    DedicatedCardComponent,
  ],
  templateUrl: "./dedicated-categories-page.component.html",
  styleUrl: "./dedicated-categories-page.component.css",
})
export class DedicatedCategoriesPageComponent implements OnInit, OnDestroy {
  vps_category?: ProductCategory;
  onLangChange?: Subscription;
  onCurrencyChange?: Subscription;
  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    public scrollService: ScrollService,
    private jwtService: JwtHelperService,
    public currencyService: CurrencyService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onCurrencyChange?.unsubscribe();
  }
  async ngOnInit() {
    this.onLangChange = this.currencyService.onChange.subscribe(() => {
      this.apiCall();
    });
    this.onLangChange = this.translateService.onLangChange.subscribe(() => {
      this.apiCall();
    });
    await this.apiCall();
    setTimeout(() => {
      this.scrollService.scrollTo(
        this.activatedRoute.snapshot.fragment || "",
        "instant"
      );
    }, 100);
  }

  async apiCall() {
    let data = this.jwtService.decodeToken(
      localStorage.getItem("apitoken") || ""
    );
    let currencyId = data ? data.currencyid : 1;
    let res = await firstValueFrom(this.apiService.getProductTree());
    if (res) {
      this.vps_category = res[1];
    }
  }
}
