<select
  class="form-control form-select"
  [name]="name"
  [attr.data-cartname]="cartName"
  class="input-extra"
  (change)="onChange.emit()"
>
  <option *ngFor="let value of values" [value]="value.id">
    {{ value.sname }}
  </option>
</select>
