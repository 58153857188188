<div class="container mb-5">
  <div class="row">
    <div class="col-md-12">
      <h1 class="text-center my-5">{{ "FAQ.title" | translate }}</h1>
    </div>
    <div class="card my-1" *ngFor="let faq of faqs">
      <div class="card-header" (click)="collapse.toggle()">
        <h3 *ngIf="translate.currentLang == 'en'">{{ faq.question.en }}</h3>
        <h3 *ngIf="translate.currentLang == 'hu'">{{ faq.question.hu }}</h3>
      </div>
      <div
        class="card-body"
        class="card-text"
        #collapse="ngbCollapse"
        [(ngbCollapse)]="faq.collapsed"
      >
        <div
          class="card-text p-2 m-2 fs-5"
          *ngIf="translate.currentLang == 'en'"
          [innerHtml]="serializer.bypassSecurityTrustHtml(faq.answer.en)"
        ></div>
        <div
          class="card-text p-2 m-2 fs-5"
          *ngIf="translate.currentLang == 'hu'"
          [innerHtml]="serializer.bypassSecurityTrustHtml(faq.answer.hu)"
        ></div>
      </div>
    </div>
  </div>
</div>
