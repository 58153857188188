import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

export class IFAQ {
  question: {
    en: string;
    hu: string;
  };
  answer: {
    en: string;
    hu: string;
  };
  collapsed: boolean = true;
}

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  faqs: IFAQ[] = [];
  constructor(private http: HttpClient) {}
  getFAQ() {
    return this.http.get<IFAQ[]>('assets/data/faq.json');
  }
}
