import { EventEmitter, Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { ApiService } from "../Api/api.service";
import { ClmCookieService } from "../Cookie/clm-cookie.service";
import { Currency } from "../../models/Currency";
import { DecimalPipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  currencies: Currency[] = [];
  onChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private apiService: ApiService,
    private cookieService: ClmCookieService,
    private decimalPipe: DecimalPipe,
    private language: TranslateService
  ) {
    this.apiService.getCurrencies().subscribe((x) => {
      this.currencies = x.data;
    });
  }

  getCurrentCurrency() {
    if (!this.cookieService.check("currency")) {
      if (this.language.currentLang == "hu") {
        this.cookieService.set("currency", "2");
      } else {
        this.cookieService.set("currency", "1");
      }
      this.apiService
        .changeCustomerCurrency(parseInt(this.cookieService.get("currency")))
        .subscribe({
          next: (x) => {
            if (x.result) {
              localStorage.setItem("apitoken", x.data);
            }
          },
        });
    }
    return this.currencies.find((curr) => {
      return curr.id.toString() == this.cookieService.get("currency");
    });
  }

  setCurrentCurrency(id: number) {
    this.cookieService.set("currency", id.toString());
    this.onChange.emit(true);
  }

  getCurrency(id: number): Currency | undefined {
    return this.currencies.find((curr) => {
      return curr.id == id;
    });
  }

  printCurrencyById(
    value: number | string | undefined,
    currencyId: number | undefined
  ): string | undefined {
    if (!currencyId) return this.printCurrency(value as number);
    let currency = this.getCurrency(currencyId);
    let val = this.decimalPipe.transform(value, `1.${currency?.rounding}-2`);
    if (val) {
      return currency?.pattern.replace("{amount}", val);
    }
    return undefined;
  }

  printCurrency(value: number | undefined): string | undefined {
    let currency = this.getCurrentCurrency();
    let val = this.decimalPipe.transform(value, `1.${currency?.rounding}-2`);
    if (val) {
      return currency?.pattern.replace("{amount}", val);
    }
    return undefined;
  }
}
