import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-number-select",
  standalone: true,
  imports: [],
  templateUrl: "./number-select.component.html",
  styleUrl: "./number-select.component.css",
})
export class NumberSelectComponent {
  @Input() max: string;
  @Input() min: string;
  @Input() step: string;
  @Input() name: string;
  @Input() cartName: string = "cartname";
  @Output() onChange: EventEmitter<never> = new EventEmitter<never>();

  onInputChange(event: any) {
    let val = Number.parseInt(event.target.value);
    if (!val) {
      event.target.value = 0;
      this.onChange.emit();
      return;
    }

    if (val < 0) {
      event.target.value = 0;
    } else if (val > Number.parseInt(this.max)) {
      event.target.value = this.max;
    } else if (val % Number.parseInt(this.step) != 0) {
      val =
        Math.floor(val / Number.parseInt(this.step)) *
        Number.parseInt(this.step);
      event.target.value = val;
    }

    this.onChange.emit();
  }
}
