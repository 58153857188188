<div class="container mt-5">
  <h3>
    <b>{{ "edu-service.title" | translate }}</b>
  </h3>
  <p>
    {{ "edu-service.p1" | translate }}
  </p>
  <p>
    {{ "edu-service.p2" | translate }}
  </p>
  <p>
    {{ "edu-service.p3" | translate }}
  </p>
  <p>
    {{ "edu-service.p4" | translate }}
  </p>
  <p>
    {{ "edu-service.p5" | translate }}
  </p>
  <a href="https://edu.clmaster.cloud/" target="_blank">
    <button class="btn btn-outline-primary my-3">
      {{ "edu-service.button" | translate }}
    </button>
  </a>
  <p>
    {{ "edu-service.p6" | translate }}
  </p>
  <p>
    {{ "edu-service.p7" | translate }}
  </p>
</div>

<div class="container mt-5 mb-5">
  <div class="message-container">
    <div class="container">
      <form>
        <div class="row">
          <div class="col-6">
            <input
              type="text"
              [(ngModel)]="name"
              [ngModelOptions]="{ standalone: true }"
              placeholder="{{ 'basic.name' | translate }}"
            />
          </div>
          <div class="w-100"></div>
          <div class="col-6">
            <input
              type="email"
              [(ngModel)]="email"
              [ngModelOptions]="{ standalone: true }"
              (focusout)="emailIsTouched = true"
              (focus)="emailIsTouched = false"
              placeholder="{{ 'basic.email' | translate }}"
              [disabled]="!!(userService.isLoggedIn | async)"
              *ngIf="!(userService.isLoggedIn | async)"
            />
            <input
              type="email"
              value="{{ userService.email | async }}"
              [disabled]="true"
              *ngIf="!!(userService.isLoggedIn | async)"
            />
            <div
              class="alert alert-danger p-1 mt-1"
              *ngIf="!isValidEmail() && emailIsTouched"
            >
              {{ "basic.email_error_invalid" | translate }}
            </div>
          </div>
          <div class="col-6">
            <input
              type="tel"
              [(ngModel)]="tel"
              [ngModelOptions]="{ standalone: true }"
              placeholder="{{ 'basic.telephone' | translate }}"
            />
          </div>
          <div class="col-12">
            <textarea
              type="text"
              [(ngModel)]="message"
              [ngModelOptions]="{ standalone: true }"
              placeholder="{{ 'basic.message' | translate }}"
            ></textarea>
          </div>
          <div class="col-12" class="text-center pt-3 pb-3">
            <button
              class="send-btn"
              [disabled]="
                !name || !email || !isValidEmail() || !tel || !message
              "
              (click)="sendMessage()"
            >
              {{ "basic.send-message" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
