<div
  class="card mt-5"
  *ngIf="order && orderDetails && subscription && price_types"
>
  <div class="card-body">
    <h4 class="card-title my-3">
      {{ subscription.name }}
    </h4>

    <h5
      class="card-subtitle mb-2 text-body-secondary"
      *ngIf="subscription.sts == 'C'"
    >
      {{ "subscription-page.activetime" | translate }} :
      <span class="fw-bold">{{ subscription.activetime }} (CEST)</span>
    </h5>
    <h5
      class="card-subtitle mb-2 text-body-secondary"
      *ngIf="subscription.sts == 'U'"
    >
      {{ "subscription-page.usable" | translate }} :
      <span class="fw-bold">{{ subscription.activetime }} (CEST)</span>
    </h5>
    <h5
      class="card-subtitle mb-2 text-body-secondary"
      *ngIf="subscription.sts == 'C'"
    >
      {{ "subscription-page.period" | translate }} :<span class="fw-bold">{{
        findPriceType(order.pricetypeid).name
      }}</span>
    </h5>
    <h5
      class="card-subtitle mb-2 text-body-secondary"
      *ngIf="subscription.sts == 'C'"
    >
      {{ "subscription-page.price" | translate }} :<span class="fw-bold"
        >{{ currencyService.printCurrencyById(full_price, order.currencyid) }}
      </span>
    </h5>
    <table class="table">
      <thead>
        <tr>
          <th>{{ "subscription-page.table.name" | translate }}</th>
          <th>{{ "basic.price.net" | translate }}</th>
          <th>{{ "basic.price.vat" | translate }}</th>
          <th>{{ "basic.price.total" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let detail of orderDetails">
          <td>{{ detail.productname }}</td>
          <td>
            {{
              currencyService.printCurrencyById(detail.netto, order.currencyid)
            }}
          </td>
          <td>
            {{
              currencyService.printCurrencyById(detail.afa, order.currencyid)
            }}
          </td>
          <td>
            {{
              currencyService.printCurrencyById(detail.brutto, order.currencyid)
            }}
          </td>
        </tr>
      </tbody>
      <tr></tr>
    </table>
    <div class="d-flex pt-4 justify-content-center">
      <button
        class="btn btn-outline-danger"
        (click)="cancel()"
        *ngIf="subscription.sts == 'C' || subscription.sts == 'R'"
      >
        {{ "subscription-page.cancellation" | translate }}
      </button>
    </div>

    <div
      class="d-flex pt-4 justify-content-center"
      *ngIf="subscription.sts == 'U' || subscription.sts == 'L'"
    >
      <span class="text-center">
        <span class="alert alert-danger">
          {{ "subscription-page.cancelled" | translate }}
        </span>
      </span>
    </div>
    <div
      class="d-flex pt-4 justify-content-center"
      *ngIf="subscription.sts == 'U' || subscription.sts == 'L'"
    >
      <button class="btn btn-outline-primary" (click)="uncancel()">
        {{ "subscription-page.uncancellation" | translate }}
      </button>
    </div>
  </div>
</div>
