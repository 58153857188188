import { faL } from "@fortawesome/free-solid-svg-icons";

interface Scripts {
  name: string;
  src: string;
  async: boolean;
  defer: boolean;
}
export const ScriptStore: Scripts[] = [
  {
    name: "molin",
    src: "https://widget.molin.ai/shop-ai.js?w=pkqi93co",
    async: false,
    defer: false,
  },
  {
    name: "googleAuth",
    src: "https://accounts.google.com/gsi/client?hl=en_EN",
    async: true,
    defer: false,
  },
];
