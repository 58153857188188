import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterLink } from "@angular/router";
import { Product } from "../../../../Core/models/Product";
import { Currency } from "../../../../Core/models/Currency";
import { CurrencyService } from "../../../../Core/Services/Currency/currency.service";
import { CartService } from "../../../../Core/Services/Cart/cart-service";

@Component({
  selector: "app-dedicated-card",
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbTooltipModule, RouterLink],
  templateUrl: "./dedicated-card.component.html",
  styleUrl: "./dedicated-card.component.css",
})
export class DedicatedCardComponent {
  constructor(
    public currencyService: CurrencyService,
    public cartService: CartService
  ) {}

  @Input() category: string;
  @Input() vps: Product;
  @Input() currency?: Currency;
}
