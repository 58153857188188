<div class="price-panel">
  <div class="price">
    <span class="price-value">{{
      currencyService.printCurrency(netto_price)
    }}</span>
  </div>
  <div class="price-info">
    + {{ "basic.price.vat" | translate }} /
    {{ "period." + selectdPeriod | translate }}
  </div>
</div>
