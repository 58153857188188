export const environment = {
  production: false,
  apiUrl: "https://test-backend.clmaster.cloud/api/",
  apiHost: "test-backend.clmaster.cloud",
  xoaUri: "test-xoa.clmaster.cloud:30443/",
  molin: false,
  revolut_sabdbox: true,
  googleAuthClientId:
    "1088218488817-k626cgnuuts9bfd9km1n7nk3qrpqn90d.apps.googleusercontent.com",
};
