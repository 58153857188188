<button class="col-3 mx-3 mt-2 btn btn-outline-primary" (click)="open(content)">
  {{ "billing_address.new" | translate }}
</button>
<div class="container mt-3" *ngIf="addresses.length > 0">
  <div class="row">
    <div class="col-6">
      <select class="form-control" [(ngModel)]="selectedAddress">
        <option *ngFor="let address of addresses" [ngValue]="address">
          {{ address.addressname }}
        </option>
      </select>
    </div>
    <div class="col-auto">
      <button class="btn btn-outline-danger" (click)="deleteAddress()">
        {{ "billing_address.delete" | translate }}
      </button>
    </div>
    <!--     <div class="col-auto">
      <button class="btn btn-outline-primary">
        {{ "billing_address.change" | translate }}
      </button>
    </div> -->
  </div>
  <div class="row mt-3">
    <div class="col">
      <span>{{ "billing_address.country" | translate }}</span>
      <select
        class="form-control"
        [(ngModel)]="selectedAddress.countryid"
        disabled
      >
        <option *ngFor="let country of countries" [value]="country.id">
          {{ country.name }}
        </option>
      </select>
    </div>
    <div class="col">
      <span>{{ "billing_address.zip_code" | translate }}</span>
      <input
        type="text"
        class="form-control"
        [value]="selectedAddress.irsz"
        disabled
      />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <span>{{ "billing_address.city" | translate }}</span>
      <input
        type="text"
        class="form-control"
        [value]="selectedAddress.city"
        disabled
      />
    </div>
    <div class="col">
      <span>{{ "billing_address.address" | translate }}</span>
      <input
        type="text"
        class="form-control"
        [value]="selectedAddress.street"
        disabled
      />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <span>{{ "billing_address.place_type" | translate }}</span>
      <select
        class="form-control"
        [(ngModel)]="selectedAddress.placetypeid"
        disabled
      >
        <option *ngFor="let placeType of placeTypes" [value]="placeType.id">
          {{ placeType.name }}
        </option>
      </select>
    </div>
    <div class="col">
      <span>{{ "billing_address.house_number" | translate }}</span>
      <input
        type="text"
        class="form-control"
        [value]="selectedAddress.housenr"
        disabled
      />
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="container">
    <CLM-billing-data (dataSend)="addBillingAddress($event)"></CLM-billing-data>
  </div>
</ng-template>
