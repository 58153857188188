import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription, map } from "rxjs";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { SubscriptionComponent } from "./subscription/subscription.component";
import { FormsModule } from "@angular/forms";
import { UserService } from "../../../Core/Services/User/user.service";

@Component({
  selector: "app-subscriptions",
  standalone: true,
  imports: [CommonModule, SubscriptionComponent, FormsModule, TranslateModule],
  templateUrl: "./subscriptions.component.html",
  styleUrl: "./subscriptions.component.scss",
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  constructor(
    private translateService: TranslateService,
    private apiService: ApiService,
    private userService: UserService
  ) {}

  selectedSubscription: any;

  subscriptions: any[] = [];

  onLangChange?: Subscription;
  ngOnInit(): void {
    this.onLangChange = this.translateService.onLangChange.subscribe((val) => {
      this.apiCalls();
    });

    this.apiCalls();
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
  }

  apiCalls() {
    this.apiService.getVPSes().subscribe((response) => {
      if (response.error) {
        this.userService.logout();
      }
      this.subscriptions = response.data.filter(
        (vps) =>
          vps.sts == "C" || vps.sts == "U" || vps.sts == "R" || vps.sts == "L"
      );
      this.selectedSubscription = this.subscriptions[0];
    });
  }
}
