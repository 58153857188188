import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserService } from '../Services/User/user.service';
import { map } from 'rxjs';

export const Activated: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router: Router = inject(Router);
  return inject(UserService)
    .isUserActivated()
    .pipe(
      map((Activated) => {
        if (Activated) return true;
        return router.createUrlTree(['register', 'activation']);
      })
    );
};

export const NotActivated: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router: Router = inject(Router);
  return inject(UserService)
    .isUserActivated()
    .pipe(
      map((Activated) => {
        if (Activated) {
          return router.createUrlTree(['/']);
        }
        return true;
      })
    );
};
