import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterLink,
} from "@angular/router";
import {
  NgbActiveOffcanvas,
  NgbCollapseModule,
  NgbOffcanvas,
} from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "../../Services/User/user.service";
import { ToastService } from "../../Toast/toast.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../Services/Api/api.service";
import { firstValueFrom, interval, Subscription } from "rxjs";
import { XoaService } from "../../Services/Xoa/xoa.service";
import { CurrencyService } from "../../Services/Currency/currency.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ClmCookieService } from "../../Services/Cookie/clm-cookie.service";
import {
  GoogleSigninButtonDirective,
  GoogleSigninButtonModule,
  SocialAuthService,
} from "@abacritt/angularx-social-login";
import { ScriptService } from "../../Services/Script/script.service";
import { environment } from "../../../../environments/environment";
declare var google: any;
@Component({
  selector: "app-user-canvas",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    RouterLink,
    TranslateModule,
  ],
  templateUrl: "./user-canvas.component.html",
  styleUrl: "./user-canvas.component.scss",
})
export class UserCanvasComponent implements OnInit, OnDestroy {
  userEmail: string = "";
  userPassword: string = "";
  private offcanvasService = inject(NgbOffcanvas);
  activeOffcanvas = inject(NgbActiveOffcanvas);
  constructor(
    private router: Router,
    public userService: UserService,
    private toastService: ToastService,
    public translateService: TranslateService,
    private apiService: ApiService,
    private currencyService: CurrencyService,
    private jwtService: JwtHelperService,
    private cookieService: ClmCookieService,
    private scriptService: ScriptService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    google.accounts.id.initialize({
      client_id: environment.googleAuthClientId,
      callback: (resp: any) => {
        if (resp && resp.credential) {
          this.apiService.googleAuth(resp.credential).subscribe({
            next: async (value) => {
              if (value.result) {
                localStorage.setItem("apitoken", value.data);
                this.userService.getUserData();
                this.userService.loggedIn();
                this.offcanvasService.dismiss("logged_in");
                this.userService.langid.subscribe((val) => {
                  this.apiService.getLanguages().subscribe({
                    next: (response) => {
                      if (response.result) {
                        let lang = response.data?.find((x) => x.id == val);
                        if (lang) {
                          this.translateService.use(lang?.code);
                          this.cookieService.set("language", lang?.code);
                        }
                      }
                      let data = this.jwtService.decodeToken(value.data);
                      this.cookieService.set("currency", data.currencyid);
                      this.currencyService.onChange.emit(true);
                    },
                  });
                });
              } else {
                this.offcanvasService.dismiss("logged_in");
                this.router.navigate(["register"], {
                  state: { google: resp.credential },
                });
              }
            },
          });
        }
      },
    });

    google.accounts.id.renderButton(
      document.getElementById("google-signin-button"),
      {
        theme: "outline",
        size: "large",
        shape: "rectangular",
        width: "370",
        auto_select: false,
        locale: this.translateService.currentLang == "hu" ? "hu_HU" : "en_GB",
      }
    );
  }

  login() {
    this.userService.login(this.userEmail, this.userPassword).subscribe({
      next: async (value) => {
        if (!value.result) {
          this.toastService.showAlert("Invalid email / password");
          return;
        }
        if (value.result && value.data) {
          localStorage.setItem("apitoken", value.data);

          let activated = await firstValueFrom(this.userService.activated);

          if (!activated) {
            this.router.navigate(["register", "activation"]);
            this.userService.getUserData();
            this.offcanvasService.dismiss("logged_in");
            return;
          }
          this.offcanvasService.dismiss("logged_in");
          this.userService.getUserData();
          this.userService.loggedIn();
          this.userService.langid.subscribe((val) => {
            this.apiService.getLanguages().subscribe({
              next: (response) => {
                if (response.result) {
                  let lang = response.data?.find((x) => x.id == val);
                  if (lang) {
                    this.translateService.use(lang?.code);
                    this.cookieService.set("language", lang?.code);
                  }
                }
                let data = this.jwtService.decodeToken(value.data);
                this.cookieService.set("currency", data.currencyid);
                this.currencyService.onChange.emit(true);
                if (this.router.url == "/register") {
                  this.router.navigate(["/"]);
                  return;
                }
              },
            });
          });
        }
      },
    });
  }
}
